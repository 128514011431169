<template>
	<div class="indexToutiao">
		<div class="itemTitle">
			<div class="titlebox">
				<div class="subject">
					<img :src="tpttTitle" style="width:100%;height:100%;" alt="">
				</div>
				<div class="more" @click="toList">
					<span >更多</span>
					<img style="width:12px;height:9px;margin-left:6px;" src="@/assets/images/more.png" alt="">
				</div>
			</div>
			<div class="firstTitle" @click="toDetail(top)">
				{{ top.title }}
			</div>
			<div class="toutiaoList">
				<img class="firstpic" :src="top.cover" @click="toDetail(top)">
				<div class="listall">
					<div class="listitem" v-for="item in list" @click="toDetail(item)" :key="item.id">{{ item.title }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { toutiaolist } from '@/api/website/toutiao.js'
export default {
	name: 'PcWebsiteToutiao',

	data() {
		return {
			tpttTitle: '',
			list: [],
			top: {}
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},
	watch: {
		site: {
			handler() {
				this.initTitle()
				this.initLists()
			}
		}
	},

	mounted() {
		this.initTitle()
		this.initLists()
	},

	methods: {


		toList() {
			const tourl = '/' + this.site.path + '/list/toutiao'
			this.$router.push(tourl)
		},



		toDetail(item) {
			const id = item.id
			const link = item.link
			let tourl = ''
			if(link){
				window.open(link, '_blank')
			}else{
				tourl = '/' + this.site.path + '/detail/toutiao/' + id
				let { href } = this.$router.resolve({ path:tourl })
				window.open(href, '_blank')
			}

		},


		
		//初始化头条样式
		initTitle() {
			if (this.site.path === 'cn') {
				this.tpttTitle = require("@/assets/images/tptt.png")
			} else {
				this.tpttTitle = require("@/assets/images/fz_tptt.png")
			}
		},

		initLists() {

			toutiaolist({ site_id: this.site.id }).then(res => {
				this.top = res.data.top
				this.list = res.data.list
			})

		}


	},
};
</script>

<style lang="scss" scoped>
.indexToutiao {
	padding-top: 10px;
	width: 795px;
	height: 434px;
	background: #FFFFFF;
	box-sizing: border-box;

	.itemTitle {
		height: 84px;
		width: 100%;


		.titlebox {
			// background:red;
			box-sizing: border-box;
			height: 84px;
			margin: 0 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.subject {
				width: 170px;
				height: 44px;
				background: #FFFFFF;
				border-radius: 2px;
			}

			.more {
				width: 62px;
				height: 24px;
				background: #FFFFFF;
				border-radius: 2px;
				border: 1px solid #5B6980;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
		}

	}

	.firstTitle {
		margin: 0 auto;
		width: 666px;
		height: 42px;
		font-size: 30px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #000000;
		line-height: 42px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		cursor: pointer;
	}

}

.toutiaoList {
	height: 298px;


	padding: 20px;
	box-sizing: border-box;
	display: flex;


}

.firstpic {
	width: 250px;
	object-fit: cover;
	height: 258px;
	cursor: pointer;
}

.listall {

	margin-left: 10px;
	// background: red;
	flex: 1;
	height: 258px;
	// width: 100%;
	width: 500px;

	.listitem {
		width: 100%;

		height: 25px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 25px;
		text-align: left;
		margin-bottom: 18px;
		margin-top: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		cursor: pointer;
	}
}
</style>