<template>

  <v-chart v-if="!hidechart" :class="{ 'chart': true, 'width100': width100 }" :option="option" />


</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GridComponent } from "echarts/components";

import { BarChart } from "echarts/charts";
import { PieChart, xAxis, LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { read } from "fs";
import { json } from "body-parser";

use([
  BarChart,
  GridComponent,
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  LineChart,
]);

export default {
  name: "bar",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },

  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },

    width100: {
      type: Boolean,
      default: false
    },
    showField: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  watch: {
    data(nv, ov) {
      if (JSON.stringify(nv) === '{}' || JSON.stringify(nv) === '[]' || JSON.stringify(nv) === '') {
        this.hidechart = true
      } else {
        this.hidechart = false
        this.option = {};
        this.createOption();
      }


    },
    showField(nv) {
      this.defaultField = nv;
    },
  },

  data() {
    return {
      hidechart: false,
      defaultField: [],
      option: {},
    };
  },

  methods: {
    createOption() {
      let legendData = [];
      let xAxisData = [];
      let series = [];
      let titlename = ''
      if (JSON.stringify(this.data) !== "{}") {

        titlename = this.data.table_name

        if (JSON.stringify(this.defaultField) === "[]") {
          this.defaultField = Object.keys(this.data.table[0]).slice(2);
        }

        this.data.header.map((item, key) => {
          this.defaultField.map(i => {
            if (i == item.prop) {
              legendData.push(item.label)
            }
          })
          // if(this.defaultField.indexOf(item.prop)){
          //     legendData.push(item.label)
          // }
        });

        this.data.table.map((item) => {
          xAxisData.push(item.dcityname);
        });

        let a = [];

        this.defaultField.map((cell, k) => {
          a[k] = [];
          this.data.table.map((item, key) => {
            a[k].push(item[cell]);
          });
        });

        series = a.map((item, key) => {
          let l = {
            name: legendData[key],
            type: "bar",
            barGap: 0,
            label: this.labelOption(),
            emphasis: {
              focus: "series",
            },
            data: item,
          };
          return l;
        });



      }


      const option = {
        title: {
          text: titlename,
          textAlign: 'center',
          x: 'center',
          width: '100%',
          y: 'top',
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: legendData,
          bottom: '10',
        },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            mark: { show: true },
            // dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar", "stack"] },
            // restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: xAxisData,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: series,
      };
      this.option = option;
      // } 
    },

    labelOption() {
      return {
        show: false,
        rotate: 75,
        align: "left",
        verticalAlign: "middle",
        position: "insideBottomLeft",
        distance: 8,
        formatter: "{c}  {name|{a}}",
        fontSize: 12,
        rich: {
          name: {},
        },
      };
    },
  },
};
</script>

<style scoped>
.chart {
  width: 795px;
  height: 285px;
  /* width:100% */
}

.width100 {
  width: 795px;
  height: 285px;
}
</style>