<template>
	<div class="ccontiner">
		<div class="top">
			<div class="subject">
				<img :src="tpttTitle" style="width:100%;height:100%;" alt="">
			</div>
		</div>


		<div class="calendarleft">
			<el-calendar class="ccalender" v-model="currentdate">
				<!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
				<template slot="dateCell" slot-scope="{date, data}">

					<div :class="['itemcell', data.isSelected ? 'is-selected' : '']" @click="tolist(data)">
						<!-- {{ data.day.split('-').slice(1).join('-') }}  -->
						{{ data.day.split('-').slice(1)[1] }}
						{{ data.isSelected ? '' : '' }}
						<div class="ccon">
							<div v-for="item in markList" :key="item.showDate">
								<div class="cconbtn" v-if="item.showDate === data.day">
									<div v-if="item.guapai > 0" class="orange"></div>
									<div v-if="item.chengjiao > 0" class="blue"></div>

								</div>

							</div>

						</div>
					</div>
				</template>

			</el-calendar>
			<div class="miaoshu">
				<div class="orange"></div>
				<div> 报名截止日</div>
				<div class="blue"></div>
				<div> 竞拍日</div>
			</div>
		</div>
	</div>


</template>

<script>
import { getDateList, getMarkList } from "@/api/index";
export default {
	name: "FrontendPcBigdataCalendar",

	data() {
		return {
			tpttTitle: '',
			currentdate: null,
			markList: [],
			listByDate: {
				bmjzr: [],
				jpr: [],
			},
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},
	watch: {
		site: {
			handler() {
				this.initTitle()
				// this.initList()
			}
		},

		currentdate(nv) {
			const ymd = this.getDateYMDString(nv);
			this.showList(ymd);
		},

		$route: {
			handler() {
				const ymd = this.getDateYMDString();
				this.showList(ymd);
			},
			immediate: true,
			deep: true
		}

	},

	mounted() {
		const ymd = this.getDateYMDString();
		// const ym = this.getDateYMString();
		this.initTitle()
		//
		this.datelist(ymd);
		this.currentdate = ymd;
		const ym = ymd.slice(0, 7);
		this.marklist(ym);
	},

	methods: {
		tolist(d) {
			const day = d.day
			const tourl = '/' + this.site.path + '/listcalendar/calendar/' + day
			this.$router.push(tourl)
		},

		//初始化头条样式
		initTitle() {
			if (this.site.path === 'cn') {
				this.tpttTitle = require("@/assets/images/tprc.png")
			} else {
				this.tpttTitle = require("@/assets/images/fz_tprc.png")
			}
		},


		getDateYMDString(string = null) {
			let d;
			if (string) {
				d = new Date(string);
			} else {
				d = new Date();
			}

			const y = d.getFullYear();
			const getm = d.getMonth();
			let day = d.getDate();

			let m = "";
			if (getm < 9) {
				m = "0" + (getm + 1);
			} else {
				m = getm + 1;
			}
			if (day < 10) {
				day = "0" + day;
			}

			return y + "-" + m + "-" + day;
		},

		toUrl() {
			this.$router.push("/index");
		},
		showList(riqi) {
			this.marklist(riqi);
			this.datelist(riqi);
		},

		marklist(riqi) {
			var that = this;
			// alert(riqi.slice(0, 7))

			const markParams = {
				month: riqi.slice(0, 7),
				site_id: this.site.id
			};

			getMarkList(markParams).then((res) => {
				that.markList = res.data;
			});
		},

		datelist(riqi) {
			var that = this;
			const params = {
				showdate: riqi,
				site_id: this.site.id
			};
			getDateList(params).then((res) => {
				that.listByDate = res.data;
			});
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .el-calendar__header {
	padding-top: 0;
}

::v-deep .el-calendar__title {
	font-size: 16px;
}

::v-deep .itemcell {
	font-size: 14px;
}

::v-deep .el-calendar-table .el-calendar-day {
	height: 45px;
}

::v-deep .el-calendar-table thead th {
	background: #00A3E4;
	color: #fff;
	border-right: solid 1px #fff;
}

.itemcell {
	height: 40px;
}

.ccon {
	height: 20px;
}

.miaoshu {
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;

	.orange,
	.blue {
		margin-left: 12px;
		margin-right: 12px;
	}
}

.ccontiner {
	width: 390px;
	height: 530px;
	margin-left: 15px;
	background: #fff;

}

.calendarleft {

	.ccalender {
		height: 386px;
	}
}

.cconbtn {
	display: flex;
	justify-content: space-around;
}

.orange {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background: #e88f09;
}

.blue {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background: #00a3e4;
}




.top {
	display: flex;
	background: #FFFFFF;

	.subject {
		width: 170px;
		height: 44px;

		border-radius: 2px;
		margin: 30px 20px;
		margin-top: 20px;
	}

}
</style>