<template>
	<div class="indexzhengce">
		<div class="itemTitle">
			<div class="titlebox">
				<div class="subject">
					<img :src="tpttTitle" style="width:100%;height:100%;" alt="">
				</div>
				<div class="more" @click="toList">
					<span>更多</span>
					<img style="width:12px;height:9px;margin-left:6px;" src="@/assets/images/more.png" alt="">
				</div>
			</div>


			<div class="linebox">
				<div class="tuwen" v-for="item in list" :key="item.id" @click="toDetail(item.id)">
					<div class="tu">
						<img class="pic" :src="item.cover_url" alt="">
					</div>
					<div class="wen">
						<div class="titlebox">
							<div class="title">
								{{ item.title }}
							</div>
							<div class="showdate">{{ item.times }}</div>
						</div>

					</div>
				</div>

			</div>

		</div>


	</div>
</template>

<script>
import { fenghuiHomeList } from '@/api/website/zhanting.js'
export default {
	name: 'PcWebsitezhengce',

	data() {
		return {
			tpttTitle: '',
			top: {},
			list: [],
			citylist: [],
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},
	watch: {
		site: {
			handler() {
				this.initTitle()
				this.initLists()
			}


		}
	},

	mounted() {
		this.initTitle()
		this.initLists()
	},

	methods: {
		tocity(item) {
			this.cityid = item.city_id
			this.initLists()
		},
		//下载文件
		downfile(item) {
			window.open(item.file_url, 'top')
		},


		toList() {
			const tourl = '/' + this.site.path + '/list/fenghui'
			this.$router.push(tourl)
		},

		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/fenghui/' + id
			// this.$router.push(tourl)
			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},

		//初始化头条样式
		initTitle() {
			if (this.site.path === 'cn') {
				this.tpttTitle = require("@/assets/images/gdfh.png")
			} else {
				this.tpttTitle = require("@/assets/images/fz_gdfh.png")
			}
		},

		initLists() {

			// const params = { site_id: this.site.id }

			fenghuiHomeList().then(res => {
				this.list = res.data
				// this.citylist = res.data.city_list
			})

		}


	},
};
</script>

<style lang="scss" scoped>
.linebox {
	width: 356px;
	margin: 0 auto
	
}


.tuwen {
	cursor: pointer;
	height: 80px;
	width: 350px;
	display: flex;
	margin: 0 auto;
	margin-bottom: 5px;

	.tu {
		width: 130px;
		height: 66px;
		// overflow: hidden;

		.pic {
			width: 130px;
			height: 66px;
			object-fit: cover;
		}

	}

	.wen {
		width: 210px;
		height: 66px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		.titlebox {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			cursor: pointer;

			.title {
				width: 100%;
				display: inline-block;
				// height: 50px;
				text-align: left;
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				line-height: 22px;
				text-align: left;;

				text-overflow: -o-ellipsis-lastline;
				overflow: hidden; //溢出内容隐藏
				text-overflow: ellipsis; //文本溢出部分用省略号表示
				display: -webkit-box; //特别显示模式
				-webkit-line-clamp: 2; //行数
				line-clamp: 2;
				-webkit-box-orient: vertical; //盒子中内容竖直排列


				.status {
					display: inline-block;

					.wangqi {
						width: 46px;
						height: 22px;
						line-height: 22px;
						border: solid 1px #00A3E4;
						border-radius: 4px;
						text-align: center;
						color: #00A3E4;
						font-size: 12px;
					}
				}

			}

			.showdate {
				width:100%;
				height: 17px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #A0A0A0;
				line-height: 17px;
				text-align: left;
			}
		}


	}
}


.line {
	cursor: pointer;
	width: 356px;
	height: 50px;
	border-bottom: solid 1px #F0F3F8;
	display: flex;

	.showrank {
		width: 48px;
		display: flex;
		justify-content: center;
		align-items: center;

		.rank {
			width: 20px;
			height: 20px;
			line-height: 20px;
			background: #E77817;
			color: #fff;
			font-weight: bold;
			font-size: 10px;
			border-radius: 4px;
		}

	}

	.showname {
		flex: 1;
		margin-left: 10px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		height: 50px;
		line-height: 50px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: left;


	}


	.showtext {

		width: 78px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #A0A0A0;
		height: 50px;
		line-height: 50px;

	}

}

.line:last-child {
	border-bottom: none;
}


.citybtn {
	width: 350px;
	margin-left: 20px;
	height: 84px;
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;

	// box-sizing: border-box;
}

.btn {
	// width: 40px;
	height: 28px;
	padding: 0 10px;
	line-height: 28px;
	background: #F2F2F2;
	border-radius: 2px;
	margin-right: 10px;
	margin-top: 10px;
	cursor: pointer;
}

.checkedbtn {
	background: #00A3E4;
	color: #fff;
}



.indexzhengce {
	padding-top: 10px;
	// width: 795px;
	height: 434px;
	background: #FFFFFF;
	box-sizing: border-box;

	.itemTitle {
		height: 84px;
		width: 100%;

		.titlebox {
			box-sizing: border-box;
			height: 100%;
			margin: 0 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.subject {
				width: 170px;
				height: 44px;
				background: #FFFFFF;
				border-radius: 2px;
			}

			.more {
				width: 62px;
				height: 24px;
				background: #FFFFFF;
				border-radius: 2px;
				border: 1px solid #5B6980;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
		}

	}



}



.firstpic {
	object-fit: cover;
	width: 250px;
	height: 258px;
	cursor: pointer;
}

.listall {

	margin-left: 10px;
	// background: red;
	flex: 1;
	height: 258px;
	// width: 100%;
	width: 500px;

	.listitem {
		width: 100%;

		height: 25px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 25px;
		text-align: left;
		margin-bottom: 18px;
		margin-top: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		cursor: pointer;
	}
}
</style>