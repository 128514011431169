import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'


//土拍数据列表
export function landdatalist(params) {
	return loginRequest({
    url: '/frontend/website/beat-data/home-all',
		method: 'post',
		data:Qs.stringify(params)
	})
}


//土拍数据列表
export function landdata(params) {
	return loginRequest({
    url: '/frontend/website/beat-data/home-top',
		method: 'post',
		data:Qs.stringify(params)
	})
}

