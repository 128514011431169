<template>
	<div class="indextudizhan">
		<div class="itemTitle">
			<div class="titlebox">
				<div class="subject">
					<img :src="tpttTitle" style="width:100%;height:100%;" alt="">
				</div>
				<div class="more" @click="toList">
					<span >更多</span>
					<img style="width:12px;height:9px;margin-left:6px;" src="@/assets/images/more.png" alt="">
				</div>
			</div>

			<div class="tudizhanList">
				<div class="video">
					<div class="shipin" @mouseenter.prevent="onmouseenter()" @mouseleave.prevent="mouseleave()">
						<video id="myVideo" class="video-js">
							<source :src="video.file_url" type="video/mp4" />
						</video>
						<div :class="{ 'zhaiyao': true, 'summary': summaryHide }">
							<div class="text">
								{{ video.summary }}
							</div>
						</div>
					</div>
					<div class="biaoti">
						{{ video.title }}
					</div>
				</div>
				<div class="news">
					<div v-for="item in news" @click="toDetail(item.id)" class="itemnews">
						<div class="newsimg">
							<img :src="item.cover_url" alt="">
						</div>
						<div class="newstitle">{{ item.title }}</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>



import { tudizhanHomeList } from '@/api/website/zhanting.js'
export default {
	name: 'PcWebsitetudizhan',


	data() {
		return {
			summaryHide: false,
			tpttTitle: '',
			list: [],
			top: {},
			video: {},
			poster: '',
			news: [],
			myPlayer: null
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},
	watch: {
		site: {
			handler() {
				this.initTitle()
				this.initLists()
			}
		},
		myPlayer: {
			handler(v) {
				console.log('====', v)
			}
		}
	},

	// beforeDestroy() {
	// 	if (this.video) {
	// 		this.video.dispose()
	// 	}
	// },
	mounted() {
		this.initTitle()
		this.initLists()
		// setTimeout(() => {
		// 	this.initVideo()
		// }, 500)


	},

	methods: {
		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/zhanting/' + id
			// this.$router.push(tourl)
			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')

			
		},

		// 鼠标移入
		onmouseenter() {
			this.summaryHide = true
		},
		// 鼠标移出
		mouseleave() {
			this.summaryHide = false
		},


		initVideo() {

			this.$nextTick((filecover) => {
				//初始化视频方法

				let myPlayer = this.$video(myVideo, {
					//确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
					controls: true,
					//自动播放属性,muted:静音播放
					// autoplay: "muted",
					poster: this.poster,
					autoplay: false,
					//建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
					preload: "auto",
					//视频播放器的显示宽度（以像素为单位）
					width: '500px',
					//视频播放器的显示高度（以像素为单位）
					height: "290px",
				})
				this.myPlayer = myPlayer
			})
		},

		//下载文件
		toDownload(item) {
			window.open(item.file_url, 'top')
		},


		toList() {
			const tourl = '/' + this.site.path + '/list/tudizhan'
			this.$router.push(tourl)
		},

		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/tudizhan/' + id
			// this.$router.push(tourl)
			
			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')

			
		},

		//初始化头条样式
		initTitle() {
			if (this.site.path === 'cn') {
				this.tpttTitle = require("@/assets/images/zgcstdz.png")
			} else {
				this.tpttTitle = require("@/assets/images/fz_zgcstdz.png")
			}
		},

		initLists() {
			tudizhanHomeList({ site_id: this.site.id }).then(res => {

				this.news = res.data.news = res.data.news
				this.video = res.data.video

				this.poster = res.data.video.cover_url
				this.initVideo()
			})
		}



	},
};
</script>

<style lang="scss" scoped>
.tudizhanList {
	.video {
		width: 500px;
		margin-left: 20px;
		height: 330px;

		.shipin {
			width: 500px;
			height: 290px;

			.zhaiyao {
				// pointer-events:none;
				position: relative;
				top: -54px;
				width: 499px;
				height: 54px;
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
				display: flex;
				justify-content: center;
				align-items: center;

				.text {
					width: 479px;
					height: 34px;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 17px;


					word-break: break-all;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					/* 这里是超出几行省略 */
					overflow: hidden;

					text-align: left;

				}

			}

			.summary {
				display: none;
			}

		}

		.biaoti {
			width: 100%;
			height: 20px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 20px;
			overflow: hidden;
			white-space: nowrap;
			margin-top: 10px;
			text-overflow: ellipsis;
			cursor: pointer;
			text-align: left;
		}
	}

	.news {
		margin-left: 15px;
		width: 240px;
		height: 330px;

		.itemnews {
			height: 170px;
			width: 240px;
			cursor: pointer;

			.newsimg {
				img {
					height: 120px;
					width: 240px;
				}

			}

			.newstitle {
				text-align: left;
				width: 234px;
				height: 30px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				line-height: 30px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

}

.indextudizhan {
	padding-top: 10px;
	width: 795px;
	height: 434px;
	background: #FFFFFF;
	box-sizing: border-box;

	.itemTitle {
		height: 84px;
		width: 100%;


		.titlebox {
			cursor: pointer;
			// background:red;
			box-sizing: border-box;
			height: 84px;
			margin: 0 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.subject {
				width: 218px;
				height: 44px;
				background: #FFFFFF;
				border-radius: 2px;
			}

			.more {
				width: 62px;
				height: 24px;
				background: #FFFFFF;
				border-radius: 2px;
				border: 1px solid #5B6980;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
		}

	}



}

.tudizhanList {
	height: 298px;


	// padding: 20px;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;

	.vlist {

		width: 192px;
		height: 248px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.coverimg {
			margin-left: 34px;
			width: 170px;
			height: 210px;
			border: 1px solid #C1C1C1;
			cursor: pointer;
		}

		.title {
			width: 170px;
			overflow: hidden;
			margin-left: 34px;
			cursor: pointer;
			width: 170px;
			height: 20px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 20px;
		}
	}

}

.firstpic {
	width: 250px;
	height: 258px;
	cursor: pointer;
}

.listall {

	margin-left: 10px;
	// background: red;
	flex: 1;
	height: 258px;
	// width: 100%;
	width: 500px;

	.listitem {
		width: 100%;

		height: 25px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 25px;
		text-align: left;
		margin-bottom: 18px;
		margin-top: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		cursor: pointer;
	}
}
</style>