
import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'


//排行area
export function area(params) {
	return loginRequest({
    url: '/frontend/website/ground-rank/area',
		method: 'post',
		data:Qs.stringify(params)
	})
}


//排行money
export function money(params) {
	return loginRequest({
    url: '/frontend/website/ground-rank/money',
		method: 'post',
		data:Qs.stringify(params)
	})
}

