<template>
	<div class="indexzhengce">
		<div class="itemTitle">
			<div class="titlebox1">
				<div class="subject">
					<img :src="tpttTitle" style="width:100%;height:100%;" alt="">
				</div>
				<div class="more" @click="toList">
					<span>更多</span>
					<img style="width:12px;height:9px;margin-left:6px;" src="@/assets/images/more.png" alt="">
				</div>
			</div>



			<div style="display:flex;justify-content: space-between;">
				<div class="jujianleft">
					<div class="list">
						<div class="listitem" v-for="item in list" @click="todetail(item)">
							<img class="cover" :src="item.cover" alt="">
							<div class="titlebox">
								<div class="title">{{ item.title }}</div>
							</div>
							<div class="locationbox">
								<div class="bluetab">{{ item.attribute_data }}</div>
								<div class="bluetab">{{ item.extent_value }}㎡</div>
								<div class="bluetab">{{ item.life_data }}</div>
							</div>
						</div>
					</div>
					<div class="coverbox">
						<img class="pic_cover" @click="towindow(addata.list_link)" :src="addata.home_file_url" />
					</div>
				</div>
				<div class="jujianright">
					<div class="zhuce" @click="toReg">
						<img class="icon" src="@/assets/images/jjrzc.png" alt="">
						<div class="text">{{ btnname }}</div>
					</div>
					<div class="denglu">
						<div class="leftdenglu" @click="toReg">
							<img class="icon" src="@/assets/images/fbxm.png" alt="">
							<div class="text">发布项目</div>
						</div>
						<div class="leftdenglu" @click="toXu">
							<img class="icon" src="@/assets/images/fbxq.png" alt="">
							<div class="text">发布需求</div>
						</div>
					</div>
					<div class="nong">
						<div class="nongtitle">
							<blue-title>农地流转</blue-title>

						</div>
						<div class="miaoshu">
							<div class="lefttext">耕地，林地，养殖用地，其他用地</div>
							<div class="righttext" @click="toNong">免费发布 >></div>
						</div>
						<div class="nonglist">
							<div class="nonglistitem" @click="todetail(item)" v-for="item in nonglist" :key="item.id">{{ item.title }}
							</div>
						</div>
					</div>


				</div>
			</div>

		</div>


	</div>
</template>

<script>
import { bingoHomeList, nongHomeList, ad } from '@/api/website/bingo.js'
import BlueTitle from './BlueTitle.vue';
import store from '@/store'
export default {
	components: { BlueTitle },
	name: 'PcWebsitezhengce',

	data() {
		return {
			btnname:'注册经纪人',
			tpttTitle: '',
			top: {},
			list: [],
			citylist: [],
			nonglist: [
			],
			addata: {}
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},
	watch: {
		site: {
			handler() {
				this.initTitle()
				this.initLists()
			}


		}
	},

	mounted() {
		this.initTitle()
		this.initLists()
		ad().then(res => {
			this.addata = res.data[0]
		})


		if (store.state.user.token != null) {
			this.btnname = "我的发布"
		} else {
			this.btnname = "我要成为经纪人"
		}

	},
	methods: {

		toReg() {
			let tourl;
			if (store.state.user.token != null) {
				// this.btnname = "我的发布"
				tourl = '/' + this.site.path + '/list/addbingo'
			} else {
				// this.btnname = "我的经纪人"
				tourl = '/' + this.site.path + '/list/jjrzc'
			}


			this.$router.push(tourl)
		},

		toXu() {
			const tourl = '/' + this.site.path + '/list/addxuqiu'
			this.$router.push(tourl)
		},
		toNong() {
			const tourl = '/' + this.site.path + '/list/addnong'
			this.$router.push(tourl)
		},



		towindow(url) {
			window.location.href = url
		},





		todetail(item) {
			const tourl = '/' + this.site.path + '/detail/jujian/' + item.id
			// this.$router.push(tourl)
			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},

		tocity(item) {
			this.cityid = item.city_id
			this.initLists()
		},
		//下载文件
		downfile(item) {
			window.open(item.file_url, 'top')
		},


		toList() {
			const tourl = '/' + this.site.path + '/list/jujian'
			this.$router.push(tourl)
		},

		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/jujian/' + id
			this.$router.push(tourl)
		},

		//初始化头条样式
		initTitle() {
			if (this.site.path === 'cn') {
				this.tpttTitle = require("@/assets/images/jjdl.png")
			}
		},

		initLists() {


			bingoHomeList().then(res => {
				// console.log(res.data)
				this.list = res.data.list

			})

			nongHomeList().then(res => {
				this.nonglist = res.data
			})


		}


	},
};
</script>

<style lang="scss" scoped>
.jujianright {
	width: 390px;
	height: 524px;
	overflow: hidden;

	.nong {



		.miaoshu {
			display: flex;
			justify-content: space-between;
			justify-items: center;
			width: 372px;
			height: 26px;
			background: rgba(231, 120, 23, 0.1);
			border-radius: 2px;

			.lefttext,
			.righttext {
				height: 26px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #E77817;
				line-height: 26px;
				margin-left: 15px;
				margin-right: 15px;
			}

			.righttext {
				cursor: pointer;
			}
		}

		.nonglist {
			display: flex;
			flex-direction: column;
			width: 372px;

			padding-left: 10px;
			box-sizing: border-box;

			.nonglistitem {
				cursor: pointer;
				width: 372px;
				height: 47px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				line-height: 47px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				border-bottom: solid 1px #F0F3F8;
				text-align: left;

				&:last-child {
					border-bottom: none;
				}
			}

		}



	}

	.zhuce {
		width: 376px;
		height: 66px;
		background: rgba(0, 163, 228, 0.1);
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	.icon {
		width: 36px;
		height: 36px;
		margin-right: 10px;
	}

	.text {
		height: 22px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
	}

	.denglu {
		width: 376px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;

		.leftdenglu {
			cursor: pointer;
			width: 180px;
			height: 66px;
			background: rgba(0, 163, 228, 0.1);
			display: flex;
			justify-content: center;
			align-items: center;


		}

	}




}

.jujianleft {
	width: 795px;
	height: 524px;

	.list {
		height: 320px;
		width: 768px;
		display: flex;
		margin-left: 20px;
		align-items: flex-start;
		align-content: flex-start;
		flex-wrap: nowrap;
		overflow: hidden;

		.listitem {
			margin-right: 24px;
			width: 240px;
			// height: 240px;
			display: flex;
			flex-direction: column;
			cursor: pointer;

			.cover {
				width: 240px;
				height: 240px;
				object-fit: cover;
			}

			.titlebox {

				height: 20px;
				width: 240px;
				margin-top: 10px;
				display: flex;
				text-align: left;
				

				.title {
					flex: 1;
					width: 240px;
					height: 20px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #000000;
					line-height: 20px;
					white-space: nowrap;
				overflow:hidden;
				text-overflow: ellipsis;
				}
			}

			.locationbox {

				height: 20px;
				width: 240px;
				margin-top: 5px;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.bluetab {
					display: inline;
					height: 20px;
					line-height: 20px;
					margin-right: 5px;
					background: rgba(0, 163, 228, 0.1);
					border-radius: 2px;
					color: #00A3E4;
					padding: 0 8px;
				}

				.location {
					// flex: 1;
					width: 240px;
					height: 17px;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #565656;
					line-height: 17px;
					text-align: left;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.typebtn {
					width: 36px;
					height: 20px;
					text-align: center;
					line-height: 20px;
					border-radius: 2px;
				}

				.typebtn1 {
					background: #FFFF00;
				}

				.typebtn2 {
					background: #FCBB81;
				}

				.typebtn3 {
					background: #E40000;
					color: #fff;
				}

				.typebtn4 {
					background: #E74FD6;
				}
			}


		}
	}

	.coverbox {
		// background: yellow;
		width: 768px;
		height: 190px;
		margin-left: 20px;
		box-sizing: border-box;

		.pic_cover {
			width: 768px;
			height: 190px;
			// border: solid 1px #000;
		}
	}

}




.linebox {
	width: 356px;
	margin: 0 auto
}


.tuwen {
	cursor: pointer;
	height: 80px;
	width: 350px;
	display: flex;
	margin: 0 auto;
	margin-bottom: 5px;

	.tu {
		width: 130px;
		height: 66px;
		// overflow: hidden;

		.pic {
			width: 130px;
			height: 66px;
			object-fit: cover;
		}

	}

	.wen {
		width: 210px;
		height: 66px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		.titlebox {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			cursor: pointer;

			.title {
				width: 100%;
				display: inline-block;
				height: 50px;
				text-align: left;
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				line-height: 22px;
				text-align: left;
				;

				text-overflow: -o-ellipsis-lastline;
				overflow: hidden; //溢出内容隐藏
				text-overflow: ellipsis; //文本溢出部分用省略号表示
				display: -webkit-box; //特别显示模式
				-webkit-line-clamp: 2; //行数
				line-clamp: 2;
				-webkit-box-orient: vertical; //盒子中内容竖直排列


				.status {
					display: inline-block;

					.wangqi {
						width: 46px;
						height: 22px;
						line-height: 22px;
						border: solid 1px #00A3E4;
						border-radius: 4px;
						text-align: center;
						color: #00A3E4;
						font-size: 12px;
					}
				}

			}

			.showdate {
				width: 100%;
				height: 17px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #A0A0A0;
				line-height: 17px;
				text-align: left;
			}
		}


	}
}


.line {
	cursor: pointer;
	width: 356px;
	height: 50px;
	border-bottom: solid 1px #F0F3F8;
	display: flex;

	.showrank {
		width: 48px;
		display: flex;
		justify-content: center;
		align-items: center;

		.rank {
			width: 20px;
			height: 20px;
			line-height: 20px;
			background: #E77817;
			color: #fff;
			font-weight: bold;
			font-size: 10px;
			border-radius: 4px;
		}

	}

	.showname {
		flex: 1;
		margin-left: 10px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		height: 50px;
		line-height: 50px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: left;


	}


	.showtext {

		width: 78px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #A0A0A0;
		height: 50px;
		line-height: 50px;

	}

}

.line:last-child {
	border-bottom: none;
}


.citybtn {
	width: 350px;
	margin-left: 20px;
	height: 84px;
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;

	// box-sizing: border-box;
}

.btn {
	// width: 40px;
	height: 28px;
	padding: 0 10px;
	line-height: 28px;
	background: #F2F2F2;
	border-radius: 2px;
	margin-right: 10px;
	margin-top: 10px;
	cursor: pointer;
}

.checkedbtn {
	background: #00A3E4;
	color: #fff;
}



.indexzhengce {
	padding-top: 10px;
	// width: 795px;
	height: 434px;
	background: #FFFFFF;
	box-sizing: border-box;

	.itemTitle {
		height: 84px;
		width: 100%;


		.titlebox1 {
			// background:red;
			box-sizing: border-box;
			height: 84px;
			margin: 0 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.subject {

				width: 170px;
				height: 44px;

				background: #FFFFFF;
				border-radius: 2px;
			}

			.more {
				width: 62px;
				height: 24px;
				background: #FFFFFF;
				border-radius: 2px;
				border: 1px solid #5B6980;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
		}

	}



}



.firstpic {
	width: 250px;
	object-fit: cover;
	height: 258px;

}
</style>